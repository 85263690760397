import React from 'react';
const VoiceoverPremiumIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="61" height="59" viewBox="0 0 61 59" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="56" height="56" rx="7" fill="url(#paint0_linear_9843_134848)" />
      <path
        d="M27.9982 32.0105C30.6612 32.0105 32.8107 29.8609 32.8107 27.198V17.573C32.8107 14.9101 30.6612 12.7605 27.9982 12.7605C25.3353 12.7605 23.1857 14.9101 23.1857 17.573V27.198C23.1857 29.8609 25.3353 32.0105 27.9982 32.0105ZM37.4789 27.198C36.6928 27.198 36.0351 27.7755 35.9068 28.5615C35.2491 32.3313 31.9605 35.2188 27.9982 35.2188C24.036 35.2188 20.7474 32.3313 20.0897 28.5615C19.9614 27.7755 19.3037 27.198 18.5176 27.198C17.5391 27.198 16.7691 28.0642 16.9135 29.0267C17.6995 33.8393 21.5495 37.609 26.3941 38.2988V41.6355C26.3941 42.5178 27.116 43.2397 27.9982 43.2397C28.8805 43.2397 29.6024 42.5178 29.6024 41.6355V38.2988C34.447 37.609 38.297 33.8393 39.083 29.0267C39.2435 28.0642 38.4574 27.198 37.4789 27.198Z"
        fill="white"
      />
      <g clipPath="url(#clip0_9843_134848)">
        <path d="M60 48C60 53.5228 55.5228 58 50 58C44.4772 58 40 53.5228 40 48C40 42.4772 44.4772 38 50 38C55.5228 38 60 42.4772 60 48Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50 56.72C54.8159 56.72 58.72 52.8159 58.72 48C58.72 43.1841 54.8159 39.28 50 39.28C45.1841 39.28 41.28 43.1841 41.28 48C41.28 52.8159 45.1841 56.72 50 56.72ZM50 58C55.5228 58 60 53.5228 60 48C60 42.4772 55.5228 38 50 38C44.4772 38 40 42.4772 40 48C40 53.5228 44.4772 58 50 58Z"
          fill="url(#paint1_linear_9843_134848)"
        />
        <path d="M46.4545 43.3601L47.0455 46.8969H43.5L46.4545 43.3601Z" fill="url(#paint2_linear_9843_134848)" />
        <path d="M53.5455 43.3601L56.5 46.8969H52.9546L53.5455 43.3601Z" fill="url(#paint3_linear_9843_134848)" />
        <path d="M47.0454 46.8969H52.9545L50 43.3601L47.0454 46.8969Z" fill="url(#paint4_linear_9843_134848)" />
        <path d="M46.4546 43.3601H50L47.0455 46.8969L46.4546 43.3601Z" fill="url(#paint5_linear_9843_134848)" />
        <path d="M50 43.3601H53.5455L52.9545 46.8969L50 43.3601Z" fill="url(#paint6_linear_9843_134848)" />
        <path d="M43.5 46.897H47.0455L50 54.5601L43.5 46.897Z" fill="url(#paint7_linear_9843_134848)" />
        <path d="M56.5 46.897H52.9545L50 54.5601L56.5 46.897Z" fill="url(#paint8_linear_9843_134848)" />
        <path d="M47.0454 46.897H52.9545L50 54.5601L47.0454 46.897Z" fill="url(#paint9_linear_9843_134848)" />
      </g>
      <rect x="39.5" y="37.5" width="21" height="21" rx="10.5" stroke="white" />
      <defs>
        <linearGradient id="paint0_linear_9843_134848" x1="-8.96" y1="-1.27534e-06" x2="64.2352" y2="14.5381" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0D22F3" />
          <stop offset="1" stopColor="#7B00F5" />
        </linearGradient>
        <linearGradient id="paint1_linear_9843_134848" x1="50" y1="38" x2="50" y2="58" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9A4DC9" />
          <stop offset="1" stopColor="#EE8EE4" />
        </linearGradient>
        <linearGradient id="paint2_linear_9843_134848" x1="44.8355" y1="45.3289" x2="47.0418" y2="46.902" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6200C4" />
          <stop offset="1" stopColor="#DEA8FF" />
        </linearGradient>
        <linearGradient id="paint3_linear_9843_134848" x1="55.0764" y1="45.1308" x2="52.9404" y2="46.8922" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6200C4" />
          <stop offset="1" stopColor="#DEA8FF" />
        </linearGradient>
        <linearGradient id="paint4_linear_9843_134848" x1="50" y1="43.3601" x2="50" y2="46.8969" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F5D9FF" />
          <stop offset="1" stopColor="#E682FF" />
        </linearGradient>
        <linearGradient id="paint5_linear_9843_134848" x1="48.2273" y1="43.3601" x2="47.0528" y2="46.9017" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5A00A2" />
          <stop offset="1" stopColor="#F0B5FF" />
        </linearGradient>
        <linearGradient id="paint6_linear_9843_134848" x1="51.7727" y1="43.3601" x2="52.9853" y2="46.8983" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5A00A2" />
          <stop offset="1" stopColor="#F0B5FF" />
        </linearGradient>
        <linearGradient id="paint7_linear_9843_134848" x1="45.1394" y1="46.9015" x2="49.9979" y2="54.5614" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E01B0" />
          <stop offset="1" stopColor="#E663E0" />
        </linearGradient>
        <linearGradient id="paint8_linear_9843_134848" x1="54.57" y1="46.9001" x2="49.9836" y2="54.4201" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E01B0" />
          <stop offset="1" stopColor="#E663E0" />
        </linearGradient>
        <linearGradient id="paint9_linear_9843_134848" x1="50" y1="46.897" x2="50" y2="54.5601" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF96E8" />
          <stop offset="1" stopColor="#3331B0" />
        </linearGradient>
        <clipPath id="clip0_9843_134848">
          <rect x="40" y="38" width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VoiceoverPremiumIcon;
