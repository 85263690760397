import Button from 'components/elements/Button';
import ModalComponent from 'components/elements/Modal';
import React, { useEffect } from 'react';

import MP3DocPremium from 'assets/icons/mp3DocPremium';
import { useTranslation } from 'hooks/useTypedTranslation';
import * as speechify from 'lib/speechify';
import { logSegmentEvent } from 'utils/analytics';

type DownloadUpsellForFreeUsersProps = {
  onClose: () => void;
  open?: boolean;
};

const DownloadUpsellForFreeUsers: React.FC<DownloadUpsellForFreeUsersProps> = ({ onClose, open = false }) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    if (open) {
      logSegmentEvent('web_app_download_free_user_upsell_shown');
    }
  }, [open]);

  const handleClose = () => {
    logSegmentEvent('web_app_download_free_user_upsell_closed');
    onClose();
  };

  const handleUpgrade = () => {
    window.open(speechify.getDirectPurchaseURL('web_app_mp3_download_upsell'), '_blank');
    logSegmentEvent('web_app_download_free_user_upsell_upgrade_clicked');
    onClose();
  };

  return (
    <ModalComponent open={open} showCloseButton onClose={handleClose} classNames="!w-[312px] dark:bg-glass-700" dialogClassNames="z-2000">
      <div className="flex w-full flex-none flex-col items-center justify-center px-8 pb-8">
        <MP3DocPremium />

        <span className="mt-4 w-[200px] text-center text-2xl font-bold text-glass-700 dark:text-glass-0">{t('Upgrade to download MP3')}</span>

        <span className="mt-2 w-[250px] text-center text-sm text-glass-600 dark:text-glass-0">
          {t('And get access to other premium features, including over 50 human-like voices and up to 5x listening speed.')}
        </span>

        <Button title={'Upgrade Now'} className="mt-6 dark:bg-electric-300" type="button" onClick={handleUpgrade} />
      </div>
    </ModalComponent>
  );
};

export default DownloadUpsellForFreeUsers;
