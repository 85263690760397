import { ContentMetaType } from '../../base';
import { BaseFileListenableContent } from '../BaseFileListenableContent';

export class PDFFileListenableContent extends BaseFileListenableContent {
  public readonly metaType: ContentMetaType = ContentMetaType.PDF;

  content = async (): Promise<ArrayBuffer> => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(this.file);
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result as ArrayBuffer);
      };
      reader.onerror = error => {
        reject(error);
      };
    });
  };
}
