import { Subscription } from '@speechifyinc/multiplatform-sdk';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { IUser } from 'interfaces';

import { logError } from '../observability';
import { firestore } from './index';

export type Entitlements = {
  userId: string;
  /** Amount of voice generation that a user can do */
  generationSecondsLeft: number;
  /** Amount of transcription that a user can do, currently no way for user to actually do any transcription but will be added  */
  transcribeSecondsLeft: number;
  /** Granted Via subscription */
  subscriptionId: string;
  /** Initially equivalent to subscription date, updates on next usage after an year */
  grantDate: string;
};

const entitlementsCollectionName = 'voiceoverEntitlements';

export const getVoiceOverEntitlements = async (user: IUser, subscription: Subscription | null): Promise<Entitlements | null> => {
  if (!user || !user.uid) return null;
  if (!subscription) return null;

  if (subscription.status.status === 'expired') return null;
  return (await queryForUserEntitlements(user.uid)) ?? null;
};

const queryForUserEntitlements = async (userId: string) => {
  try {
    const entitlementsSnapshot = await getDocs(query(collection(firestore, entitlementsCollectionName), where('userId', '==', userId)));
    const entitlements = entitlementsSnapshot.docs.map(doc => doc.data() as Entitlements);
    return entitlements.length > 0 ? entitlements[0] : null;
  } catch (e) {
    logError(e as Error);
    return null;
  }
};
