import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'store';

import { ErrorSource } from 'config/constants/errors';
import { logError } from 'lib/observability';
import * as speechify from 'lib/speechify';
import { showUpsellModal as showContextualUpsellModal } from 'modules/upsells/stores/actions/showUpsellModal';
import { UpsellModalType } from 'modules/upsells/stores/types';
import { actions as importActions } from 'store/import';

interface UpsellResult {
  currentFileCount: number;
  shouldUpsell: boolean;
}

interface UseFileUpsellReturn {
  checkForUpsell: (fileCount?: number) => Promise<boolean>;
  maxFileCount: number;
}

export function useFileUpsell(): UseFileUpsellReturn {
  const dispatch = useDispatch();
  const maxFileCount = 5;
  const user = useSelector(state => state.auth.user);
  const { items: libraryItems } = useSelector(state => state.library);

  const upsellPromise = useMemo<Promise<UpsellResult>>(async () => {
    if (!user?.uid || !speechify.canUpgrade(user)) {
      return { shouldUpsell: false, currentFileCount: Number.MIN_SAFE_INTEGER };
    }

    let recordsCount = 0;
    try {
      recordsCount = await speechify.fetchAllRecordItemsOwnedByCurrentUser();
    } catch (err) {
      logError(err as Error, ErrorSource.FILE_UPSELL);
    }
    const currentFileCount = Math.max(recordsCount, libraryItems.length);
    return { shouldUpsell: currentFileCount >= maxFileCount, currentFileCount };
  }, [user, libraryItems.length, maxFileCount]);

  const upsellRef = useRef(upsellPromise);
  upsellRef.current = upsellPromise;

  const checkForUpsell = useCallback(
    async (fileCount = 1): Promise<boolean> => {
      const { shouldUpsell, currentFileCount } = await upsellRef.current;
      const showUpsellModal = shouldUpsell || currentFileCount + fileCount > maxFileCount;

      if (showUpsellModal) {
        dispatch(importActions.closeImportDialog());
        showContextualUpsellModal(UpsellModalType.ContextualUnlimitedFiles);
      }

      return showUpsellModal;
    },
    [maxFileCount, dispatch]
  );

  const hookReturn = useMemo<UseFileUpsellReturn>(
    () => ({
      checkForUpsell,
      maxFileCount
    }),
    [checkForUpsell, maxFileCount]
  );

  return hookReturn;
}
