import { createFileListenableContent } from './factory';
import { EPUBFileListenableContent } from './impl/EPUBFileListenableContent';
import { HTMLFileListenableContent } from './impl/HTMLFileListenableContent';
import { PDFFileListenableContent } from './impl/PDFFileListenableContent';
import { TXTFileListenableContent } from './impl/TXTFileListenableContent';

type FileListenableContent = PDFFileListenableContent | EPUBFileListenableContent | HTMLFileListenableContent | TXTFileListenableContent;

export {
  createFileListenableContent,
  EPUBFileListenableContent,
  type FileListenableContent,
  HTMLFileListenableContent,
  PDFFileListenableContent,
  TXTFileListenableContent
};
