import Button from 'components/elements/Button';
import ModalComponent from 'components/elements/Modal';
import React, { useEffect, useState } from 'react';

import MP3DocPremium from 'assets/icons/mp3DocPremium';
import { useTranslation } from 'hooks/useTypedTranslation';
import * as speechify from 'lib/speechify';
import { logSegmentEvent } from 'utils/analytics';

import { logError } from '../../lib/observability';

type DownloadUpsellForTrialUsersProps = {
  onClose: () => void;
  onOpenDownload: () => void;
  open?: boolean;
};

const DownloadUpsellForTrialUsers: React.FC<DownloadUpsellForTrialUsersProps> = ({ onClose, onOpenDownload, open = false }) => {
  const { t } = useTranslation('common');

  const [trialSkipped, setTrialSkipped] = useState(false);

  useEffect(() => {
    if (open) {
      logSegmentEvent('web_app_download_trial_user_upsell_shown');
    }
  }, [open]);

  const handleClose = () => {
    logSegmentEvent('web_app_download_trial_user_upsell_closed');
    onClose();
  };

  const handlePay = async () => {
    try {
      logSegmentEvent('web_app_download_trial_user_upsell_pay_clicked');

      // pay
      await speechify.skipTrial();

      // show success
      setTrialSkipped(true);
    } catch (e) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(e);
      onClose();
    }
  };

  const handleOpenDownload = () => {
    logSegmentEvent('web_app_download_trial_user_upsell_download_clicked');
    onOpenDownload();
    onClose();
  };

  return (
    <ModalComponent
      open={open}
      showCloseButton
      onClose={handleClose}
      classNames={`${trialSkipped ? '!w-[312px] dark:bg-glass-700' : '!w-[410px] dark:bg-glass-700'}`}
      dialogClassNames="z-2000"
    >
      <div className="flex w-full flex-none flex-col items-center justify-center px-8 pb-8">
        {!trialSkipped ? (
          <>
            <MP3DocPremium />
            <span className="mt-4 w-[220px] text-center text-2xl font-bold text-glass-700 dark:text-glass-0">{t('Skip trial period to download MP3')}</span>
            <span className="mt-2 w-[346px] text-center text-sm text-glass-600 dark:text-glass-0">
              {t('Download of MP3 files is not available for trial users. Skip the trial and become a paid premium user now!')}
            </span>
            <Button title={t('Pay Now')} className="mt-4 dark:bg-electric-300" type="button" onClick={handlePay} />
          </>
        ) : (
          <>
            <span className="text-7xl">🎉</span>
            <span className="mt-4 w-[250px] text-center text-2xl font-bold text-glass-700 dark:text-glass-0">{t('Purchase successful!')}</span>
            <span className="mt-2 w-[250px] text-center text-sm text-glass-600 dark:text-glass-0">
              {t('You now have immediate access to download the MP3 file.')}
            </span>
            <Button title={t('Download as MP3')} className="mt-4 dark:bg-electric-300" type="button" onClick={handleOpenDownload} />
          </>
        )}
      </div>
    </ModalComponent>
  );
};

export default DownloadUpsellForTrialUsers;
