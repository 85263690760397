import Uppy, { UploadedUppyFile } from '@uppy/core';
import Transloadit, { COMPANION_URL } from '@uppy/transloadit';
import UrlPlugin from '@uppy/url';

import { ALLOWED_MIME_TYPES } from 'interfaces/import';
import { auth } from 'lib/speechify';

const uppy = new Uppy({
  id: 'integrations-upload',
  autoProceed: false,
  debug: false,
  allowMultipleUploadBatches: true,
  restrictions: {
    maxNumberOfFiles: 0,
    allowedFileTypes: Object.values(ALLOWED_MIME_TYPES)
  }
});

function setupUppyPlugins() {
  const transloaditKey = process.env.NEXT_PUBLIC_TRANSLOADIT_KEY;
  const transloaditTemplateId = process.env.NEXT_PUBLIC_TRANSLOADIT_TEMPLATE;
  const transloaditPlugin = uppy.getPlugin<Transloadit>('transloadit');
  const userId = auth.currentUser?.uid;

  if (!transloaditKey || !transloaditTemplateId || !userId) {
    throw new Error('Unable to initialize transloadit plugin');
  }

  if (transloaditPlugin) {
    transloaditPlugin.setOptions({ fields: { userId } });
  } else {
    uppy.use(Transloadit, {
      id: 'transloadit',
      service: 'https://api2.transloadit.com',
      params: {
        auth: { key: transloaditKey },
        template_id: transloaditTemplateId
      },
      waitForEncoding: true,
      waitForMetadata: true,
      importFromUploadURLs: false,
      alwaysRunAssembly: false,
      signature: undefined,
      fields: { userId },
      limit: 0
    });
    uppy.use(UrlPlugin, {
      id: 'url',
      companionUrl: COMPANION_URL
    });
  }
}

export function getUppy(): Uppy {
  setupUppyPlugins();
  return uppy;
}

// Need to remove uploaded files from uppy state to allow re-upload
// Uppy has `removeFiles` method though it is not exposed
// `removeFile` method triggers api call to transloadit to update assembly - which we don't need
// Need to clear uploaded state manually
uppy.on('transloadit:complete', assembly => {
  const uppyState = uppy.getState();
  const files = { ...uppyState.files };
  const currentUploads = { ...uppyState.currentUploads };

  const fileIds = new Set<string>();

  for (const [fileId, file] of Object.entries(files)) {
    if ((file as UploadedUppyFile<{ assembly_url: string }, Record<string, unknown>>).meta.assembly_url === assembly.assembly_url) {
      fileIds.add(fileId);
      delete files[fileId];
    }
  }

  for (const [uploadId, upload] of Object.entries(currentUploads as Record<string, { fileIDs: string[] }>)) {
    upload.fileIDs = upload.fileIDs.filter(fileId => !fileIds.has(fileId));

    if (upload.fileIDs.length === 0) {
      delete currentUploads[uploadId];
    }
  }

  uppy.setState({ ...uppyState, files, currentUploads });
});
