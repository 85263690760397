import { useCallback, useEffect, useState } from 'react';

export default function useMediaQuery(query: string, onChange?: (e: MediaQueryListEvent) => void) {
  const [matches, setMatches] = useState(null);

  const onMediaQueryChange = useCallback(
    (e: MediaQueryListEvent) => {
      // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      setMatches(e.matches);
      onChange && onChange(e);
    },
    [onChange]
  );

  useEffect(() => {
    const mq = window.matchMedia(query);

    // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    setMatches(mq.matches);
    mq.addEventListener('change', onMediaQueryChange);

    return () => {
      mq.removeEventListener('change', onMediaQueryChange);
    };
    // ESLint: React Hook useEffect has missing dependencies: 'onMediaQueryChange' and 'query'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    matches
  };
}
