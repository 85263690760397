import { createNonPersistentStore } from 'lib/zustand';
import type { ListenableContent } from 'modules/sdk/lib';

export enum ReaderMode {
  CLASSIC = 'classic',
  BOOK = 'book'
}

type ReaderStateLoading = {
  currentReaderMode: undefined;
};

type BookReaderState = {
  currentReaderMode: ReaderMode.BOOK;
};

type ClassicReaderState = {
  currentReaderMode: ReaderMode.CLASSIC;
};

export type ReaderState = ReaderStateLoading | BookReaderState | ClassicReaderState;

export const useReaderStore = createNonPersistentStore<ReaderState>(
  () => {
    return {
      currentReaderMode: undefined
    };
  },
  {
    isListeningScreenStore: true
  }
);

export const isClassicReader = (readerState: ReaderState): readerState is ClassicReaderState => {
  return readerState.currentReaderMode === ReaderMode.CLASSIC;
};

const determineReaderMode = (listenableContent: ListenableContent): ReaderMode => {
  const newMode = listenableContent.isPDF() ? ReaderMode.BOOK : ReaderMode.CLASSIC;
  useReaderStore.setState(() => ({ currentReaderMode: newMode }));
  return newMode;
};

export const readerStoreActions = {
  determineReaderMode
};
