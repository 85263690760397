import styled from '@emotion/styled';
import { XIcon } from '@heroicons/react/outline';
import { CursorQueryBuilder, SpeechQueryBuilder, SpeechSentence, VoiceSpec, VoiceSpecOfAvailableVoice } from '@speechifyinc/multiplatform-sdk';
import Button from 'components/elements/Button';
import ModalComponent from 'components/elements/Modal';
import TextInput from 'components/elements/TextInput';
import { importSDK } from 'components/experience/readers/newsdk';
import { BookReadingInfo } from 'components/experience/readers/newsdk/ReadingInfo';
import { IUser } from 'interfaces';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useDispatch, useSelector } from 'store';
import { doItemsMatch } from 'utils';

import { useTranslation } from 'hooks/useTypedTranslation';
import * as faro from 'lib/observability';
import * as speechify from 'lib/speechify';
import { promisify } from 'lib/speechify/adaptors/promisify';
import { Entitlements, getVoiceOverEntitlements } from 'lib/speechify/voiceoverEntitlements';
import { selectors as librarySelectors } from 'store/library';
import { actions as toastActions } from 'store/toast';
import { logSegmentEvent } from 'utils/analytics';

const defaultVoice = {
  displayName: 'John',
  name: 'Matthew',
  engine: 'neural',
  language: 'en-US'
};

const Spinner = styled(FaSpinner)`
  color: rgb(33, 55, 252);
  animation: spin infinite 2s linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

type DownloadProps = {
  onClose: () => void;
  itemId: string;
  open?: boolean;
};

const Download: React.FC<DownloadProps> = ({ itemId, onClose, open = false }) => {
  const item = useSelector(state => librarySelectors.getById(itemId)(state), doItemsMatch);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [bookReadingInfo, setBookReadingInfo] = useState<BookReadingInfo | null>(null);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [fromPage, setFromPage] = useState('1');
  const [toPage, setToPage] = useState('');
  const [pagesError, setPagesError] = useState<string | null>(null);
  const [minutesLimitedPerDownload, setMinutesLimitedPerDownload] = useState<number | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(true);

  const [downloadStarted, setDownloadStarted] = useState(false);
  const [downloadFinished, setDownloadFinished] = useState(false);
  const [downloadError, setDownloadError] = useState<string | null>(null);

  const celebrityVoices = ['Snoop Dogg', 'Gwyneth Paltrow', 'Mr. President', 'Dwight', 'Mr. Beast'];
  const [currentVoice, setCurrentVoice] = useState<VoiceSpecOfAvailableVoice | undefined>(undefined);
  const [voiceShouldChange, setVoiceShouldChange] = useState<boolean>(false);

  const toPageLimitedRef = useRef<boolean>();

  const [sentencesToDownload, setSentencesToDownload] = useState<SpeechSentence[]>([]);

  const startNotificationId = 'download-mp3-started-notification';
  const successNotificationId = 'download-mp3-success-notification';
  const failNotificationId = 'download-mp3-fail-notification';

  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const currentUser = useSelector<IUser>(state => state.auth.user);
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [downLoadEntitlements, setDownLoadEntitlements] = useState<Entitlements>(null);

  const averageCharactersPerWord = 5;
  const wordsPerMinute = 200;

  useEffect(() => {
    if (!open) return;

    (async () => {
      if (currentUser) {
        // @ts-expect-error TS(2345): Argument of type 'Subscription | undefined' is not... Remove this comment to see the full error message
        const entitlements = await getVoiceOverEntitlements(currentUser, currentUser.subscription);
        // @ts-expect-error TS(2345): Argument of type 'Entitlements | null' is not assi... Remove this comment to see the full error message
        setDownLoadEntitlements(entitlements);
      }
    })();
  }, [open, currentUser]);

  useEffect(() => {
    if (open) {
      logSegmentEvent('web_app_download_modal_shown');

      dispatch(toastActions.remove(startNotificationId));
      dispatch(toastActions.remove(successNotificationId));
      dispatch(toastActions.remove(failNotificationId));
    }
  }, [dispatch, open]);

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFromPageChange = (event: any) => {
    const value = event.target.value;
    setMinutesLimitedPerDownload(undefined);
    setSentencesToDownload([]);
    setFromPage(value?.toString());
  };

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onToPageChange = (event: any) => {
    const value = event.target.value;
    setMinutesLimitedPerDownload(undefined);
    setSentencesToDownload([]);
    setToPage(value?.toString());
  };

  useEffect(() => {
    if (open && item && !bookReadingInfo) {
      (async () => {
        const { createBookReaderFromLibraryItem } = await importSDK();
        const readingInfo = await faro.instrumentAction(async () => await createBookReaderFromLibraryItem(item), 'load-item-resource');
        if (readingInfo) {
          const { bookView } = readingInfo;
          const voice = readingInfo.voiceOfCurrentUtterance;
          setNumberOfPages(bookView.getMetadata().numberOfPages);
          setCurrentVoice(voice);
          setBookReadingInfo(readingInfo);
          setLoading(false);
        }
      })();
      return () => {
        if (bookReadingInfo) {
          (bookReadingInfo as BookReadingInfo).destroy();
        }
      };
    }
  }, [open, item, bookReadingInfo]);

  const calculateContentToDownload = debounce(async () => {
    if (!numberOfPages) {
      return;
    }

    if (toPageLimitedRef.current) {
      toPageLimitedRef.current = false;
      return;
    }

    const fromPageNumber = +fromPage;
    const toPageNumber = +toPage;

    if (!(fromPageNumber >= 1 && fromPageNumber <= numberOfPages) || !(toPageNumber >= 1 && toPageNumber <= numberOfPages)) {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      setPagesError(t('Page number should be between', { lowerBound: 1, upperBound: numberOfPages }));
      return;
    }

    if (fromPageNumber > toPageNumber) {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      setPagesError(t('Please enter the valid page range'));
      return;
    }

    try {
      setLoading(true);
      if (bookReadingInfo && numberOfPages) {
        const { bookView, speechView } = bookReadingInfo;

        setSentencesToDownload([]);
        setPagesError(null);

        const pageNumbers = Array.from({ length: toPageNumber - fromPageNumber + 1 }, (_, index) => index + fromPageNumber - 1);

        const minutesAllowedPerDownload = 60;
        const limitLength = wordsPerMinute * minutesAllowedPerDownload * averageCharactersPerWord;

        let totalSentences: SpeechSentence[] = [];
        let totalLength = 0;
        let lastPageIndex = 0;

        for (const pageIndex of pageNumbers) {
          const [page] = await promisify(bookView.getPages.bind(bookView))([pageIndex]);

          const sentenceQuery = SpeechQueryBuilder.fromBounds(CursorQueryBuilder.fromCursor(page.start), CursorQueryBuilder.fromCursor(page.end));
          const { sentences } = await promisify(speechView.getSpeech.bind(speechView))(sentenceQuery);

          const lengthForSentences = sentences.reduce((accumulator, curSentence) => accumulator + (curSentence.text?.length ?? 0), 0);

          if (totalLength + lengthForSentences > limitLength) {
            break;
          }

          totalLength += lengthForSentences;
          totalSentences = [...totalSentences, ...sentences];
          lastPageIndex = pageIndex;
        }

        if (+toPage !== lastPageIndex + 1) {
          const newToPage = (lastPageIndex + 1).toString();
          toPageLimitedRef.current = true;
          setToPage(newToPage);
          setMinutesLimitedPerDownload(minutesAllowedPerDownload);
        }

        // Show error message if limit is reached and no sentences are eligible for download
        if (totalLength === 0 && totalSentences.length === 0) {
          setMinutesLimitedPerDownload(minutesAllowedPerDownload);
        }

        setSentencesToDownload(totalSentences);
      }
    } catch (e) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      faro.logError(e);
    } finally {
      setLoading(false);
    }
  }, 300);

  useEffect(() => {
    if (!open) return;

    calculateContentToDownload();

    return () => {
      calculateContentToDownload.cancel();
    };
    // ESLint: React Hook useEffect has a missing dependency: 'calculateContentToDownload'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, bookReadingInfo, numberOfPages, fromPage, toPage, downLoadEntitlements]);

  useEffect(() => {
    if (!open) return;

    if (currentVoice) {
      setVoiceShouldChange(celebrityVoices.includes(currentVoice.displayName));
    } else {
      setVoiceShouldChange(false);
    }
    // ESLint: React Hook useEffect has a missing dependency: 'celebrityVoices'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, currentVoice]);

  const handleClose = () => {
    if (downloadStarted && !downloadFinished) {
      return false;
    }
    logSegmentEvent('web_app_download_modal_closed');
    onClose();
  };

  const handleDownload = async () => {
    const totalCharacters = sentencesToDownload.reduce((accumulator, curSentence) => accumulator + (curSentence.text?.length ?? 0), 0);

    const voice =
      !voiceShouldChange && currentVoice
        ? {
            displayName: currentVoice.displayName,
            name: (currentVoice as VoiceSpec.CVLVoiceSpec).name,
            engine: (currentVoice as VoiceSpec.CVLVoiceSpec).engine,
            language: currentVoice.languageCode ?? 'en-US'
          }
        : defaultVoice;

    const secondsEstimated = (totalCharacters / (wordsPerMinute * averageCharactersPerWord)) * 60;

    const downloadAnalyticsData = {
      itemId: item.id,
      itemTitle: item.title,
      itemSourceUrl: item.sourceURL,
      fromPage,
      toPage,
      totalCharacters,
      secondsEstimated,
      voice,
      downLoadEntitlements
    };

    logSegmentEvent('web_app_download_modal_download_started', downloadAnalyticsData);
    setDownloadStarted(true);

    try {
      const downloadUrl = await speechify.generateAudioForDownload(sentencesToDownload, voice);
      dispatch(
        toastActions.add({
          id: startNotificationId,
          description: t('Your download has started!'),
          duration: 2000,
          type: 'info'
        })
      );

      const temporaryLink = document.createElement('a');
      temporaryLink.href = downloadUrl;
      temporaryLink.style.display = 'none';
      temporaryLink.download = `${item.title}_pages_${fromPage}_to_${toPage}.mp3`;
      document.body.appendChild(temporaryLink);
      temporaryLink.click();
      document.body.removeChild(temporaryLink);
      URL.revokeObjectURL(downloadUrl);

      logSegmentEvent('web_app_download_modal_download_success', downloadAnalyticsData);

      dispatch(
        toastActions.add({
          id: successNotificationId,
          description: t('Your MP3 file has been successfully downloaded!'),
          duration: 60 * 60 * 1000,
          type: 'success'
        })
      );
    } catch (e) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      setDownloadError(e.toString());
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      faro.logError(e);
      logSegmentEvent('web_app_download_modal_download_failed', downloadAnalyticsData);
      dispatch(
        toastActions.add({
          id: failNotificationId,
          title: t('Download failed!'),
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          description: e.toString(),
          duration: 60 * 60 * 1000,
          type: 'error'
        })
      );
    } finally {
      dispatch(toastActions.remove(startNotificationId));
      setDownloadFinished(true);
      onClose();
    }
  };

  return (
    <ModalComponent open={open} onClose={handleClose} classNames="!w-[472px] dark:bg-glass-700" dialogClassNames="z-2000">
      <XIcon className="absolute right-3 top-3 float-right block h-5 w-5 cursor-pointer dark:text-glass-350 sm:h-6" aria-hidden="true" onClick={handleClose} />
      <div className="flex flex-col items-center justify-center">
        <div className="flex h-12 w-full justify-center border-b border-solid border-glass-300 px-4 py-3 text-lg font-bold dark:border-glass-600 dark:text-glass-0">
          {t('Download as MP3')}
        </div>
        {downloadStarted ? (
          downloadFinished ? (
            <div className="flex w-full flex-none flex-col items-center justify-center px-8 py-6 dark:text-glass-0">
              {downloadError ? (
                <>
                  <span>{downloadError}</span>
                </>
              ) : (
                <span>{t('Your MP3 file has been successfully downloaded!')}</span>
              )}
            </div>
          ) : (
            <div className="flex w-full flex-none flex-col items-center justify-center gap-3 px-8 py-6 dark:text-glass-350">
              <span>{t('Your file will be downloaded soon. Please don’t close this window. It may take a few minutes.')}</span>
              <div className="flex items-center justify-center">
                <Spinner size={32} />
              </div>
            </div>
          )
        ) : (
          <div className="flex w-full flex-none flex-col items-center justify-center px-8 py-6">
            <div className="flex h-12 w-full items-center justify-between dark:text-glass-0">
              <span>{t('Page from')}</span>
              <TextInput
                className="!w-32 dark:border dark:border-solid dark:border-glass-600 dark:bg-glass-800 dark:text-glass-0"
                maxLength={4}
                name="from"
                type="number"
                min="1"
                max={numberOfPages}
                value={fromPage}
                onChange={onFromPageChange}
              />
              <span>{t('to')}</span>
              <TextInput
                className="!w-32 dark:border dark:border-solid dark:border-glass-600 dark:bg-glass-800 dark:text-glass-0"
                maxLength={4}
                name="to"
                type="number"
                min="1"
                max={numberOfPages}
                value={toPage}
                onChange={onToPageChange}
              />
            </div>
            {pagesError && (
              <div className="mt-1 flex w-full">
                <span className="px-0.5 text-xs text-red-600">{pagesError}</span>
              </div>
            )}
            {minutesLimitedPerDownload !== undefined && (
              <div className="mt-1 flex w-full">
                <span className="px-0.5 text-xs text-glass-600 dark:text-glass-350">
                  {t('To avoid exceeding your premium voice limit, your download has been limited to x minutes.', { minutes: minutesLimitedPerDownload })}
                </span>
              </div>
            )}
            {voiceShouldChange && (
              <div className="mt-3 flex items-center gap-4 rounded-lg bg-glass-200 px-3 py-4 dark:bg-glass-800">
                <img className="h-16 w-16" src="https://storage.googleapis.com/centralized-voice-list/base/avatars/en-US-Matthew-neural.webp" />
                <div>
                  <div className="text-base font-bold text-glass-700 dark:text-glass-0">{`${t('MP3 file voice changed to')} ${defaultVoice.displayName}`}</div>
                  <div className="text-sm text-glass-600 dark:text-glass-350">
                    {t(
                      'Unfortunately, we cannot download MP3 with your currently selected voice due to author’s rights. It can only be used online in Speechify.'
                    )}
                  </div>
                </div>
              </div>
            )}
            <Button
              title={t('Download')}
              className="mt-4 w-80 dark:bg-electric-350 dark:disabled:bg-glass-350"
              type="button"
              disabled={sentencesToDownload?.length === 0}
              onClick={handleDownload}
            />
            {loading && (
              <div className="absolute flex items-center justify-center">
                <Spinner size={24} />
              </div>
            )}
          </div>
        )}
      </div>
    </ModalComponent>
  );
};

export default Download;
