import type { SpeechifyURI } from '@speechifyinc/multiplatform-sdk';
import { WebAppImportFlow } from 'components/library/import/constants';

import type { Callback } from 'lib/speechify/adaptors/lib/typeAliases';

import { SDKLibraryFacade } from '../../library';
import { BaseListenableContent, ContentMetaType, ContentType } from '../base';

export abstract class BaseURLListenableContent extends BaseListenableContent {
  public readonly importFlow: WebAppImportFlow;

  public readonly folderId?: string;
  public readonly contentType: ContentType = ContentType.url;

  public readonly url: string;

  protected _name: string;
  protected _mimeType: string;

  public readonly onImportCompleteCallback: Callback<SpeechifyURI>;

  constructor({
    folderId,
    mimeType,
    name,
    onImportCompleteCallback,
    url,
    importFlow
  }: {
    folderId?: string;
    mimeType: string;
    name: string;
    onImportCompleteCallback: Callback<SpeechifyURI>;
    url: string;
    importFlow: WebAppImportFlow;
  }) {
    super();
    this._mimeType = mimeType;
    this._name = name || url;
    this.url = url;
    this.folderId = folderId;
    this.onImportCompleteCallback = onImportCompleteCallback;
    this.importFlow = importFlow;
  }

  abstract get metaType(): ContentMetaType;

  content = async (): Promise<string> => {
    return this.url;
  };

  public get title(): string {
    return this._titleOverride || this._name;
  }

  public get wordCount(): number {
    // TODO: Implement word count for URL item
    return 0;
  }

  override onItemIdReady(itemId: string): void {
    super.onItemIdReady(itemId);

    SDKLibraryFacade.singleton.getItem(itemId).then(item => {
      this.updateTitle(item.title);
    });
  }
}
