import { WebAppImportFlow } from 'components/library/import/constants';
import { ImportOptions } from 'components/library/import/upload/import';

import { Nullable } from 'utils/types';

import { BaseListenableContent, ContentMetaType, ContentType } from '../base';
import { ImportCompleteCallback } from '../utils';

export abstract class BaseFileListenableContent extends BaseListenableContent {
  public readonly file: File;
  public readonly contentType: ContentType = ContentType.file;

  public readonly folderId: Promise<string | undefined>;
  private _folderIdPromiseResolver!: (value: string | undefined) => void;

  public readonly analyticsProperties: Record<string, unknown> | undefined;

  public readonly onImportCompleteCallback: Promise<ImportCompleteCallback>;
  private _onImportCompleteCallbackPromiseResolver!: (value: ImportCompleteCallback) => void;

  constructor(
    file: File,
    public readonly importFlow: WebAppImportFlow,
    analyticsProperties?: Record<string, unknown>
  ) {
    super();
    this.file = file;
    this.folderId = new Promise<string | undefined>(resolve => {
      this._folderIdPromiseResolver = resolve;
    });
    this.analyticsProperties = analyticsProperties || {};
    this.onImportCompleteCallback = new Promise<ImportCompleteCallback>(resolve => {
      this._onImportCompleteCallbackPromiseResolver = resolve;
    });
  }

  public setImportOptions = ({
    callback,
    folderId
  }: Pick<ImportOptions, 'folderId'> & {
    callback: ImportCompleteCallback;
  }) => {
    this._onImportCompleteCallbackPromiseResolver(callback);
    this._folderIdPromiseResolver(folderId);
  };

  get title(): string {
    return this._titleOverride || this.file.name;
  }

  abstract get metaType(): ContentMetaType;

  override get wordCount(): Nullable<number> {
    // TODO(overhaul): Implement wordCount for instant listening
    return null;
  }
}
