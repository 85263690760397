import React from 'react';
export const Cross: React.FC<React.SVGAttributes<SVGSVGElement>> = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 17" width="18" height="17" {...props}>
      <path
        fill="currentColor"
        d="M2.33 14.34a.74.74 0 0 0 0 1.04c.3.28.77.28 1.06 0l5.3-5.31 5.32 5.31c.28.28.77.3 1.06 0a.75.75 0 0 0 0-1.04L9.75 9.02l5.32-5.32A.74.74 0 0 0 14 2.66L8.7 7.96l-5.31-5.3a.74.74 0 0 0-1.06 0 .75.75 0 0 0 0 1.04l5.32 5.32-5.32 5.32Z"
      />
    </svg>
  );
};
