import React from 'react';

export default function ArrowOutwardVoiceover(props: $TSFixMe) {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.8828 9.24219C11.8828 9.75 11.4922 10.1406 11.0312 10.1406C10.5625 10.1406 10.1875 9.73438 10.1875 9.26562V6.35156L10.3203 3.11719L9.05469 4.53906L1.60156 11.9922C1.42188 12.1719 1.21094 12.2578 0.992188 12.2578C0.523438 12.2578 0.109375 11.8359 0.109375 11.3828C0.109375 11.1641 0.203125 10.9453 0.382812 10.7656L7.82812 3.3125L9.25 2.0625L5.88281 2.17188H3.10156C2.63281 2.17188 2.23438 1.79688 2.23438 1.34375C2.23438 0.882812 2.60156 0.492188 3.125 0.492188H10.9688C11.5234 0.492188 11.8828 0.859375 11.8828 1.39844V9.24219Z"
        fill="#587393"
      />
    </svg>
  );
}
