import type { SpeechifyURI } from '@speechifyinc/multiplatform-sdk';
import { CLASSIC_READER_MIME_TYPES, WebAppImportFlow } from 'components/library/import/constants';
import { ImportOptions, NonInstantListeningImportSource } from 'components/library/import/upload/import';
import { isUrlImport } from 'components/library/import/utils';
import { ALLOWED_MIME_TYPES } from 'interfaces';

import { wrapWithCorsProxy } from 'lib/speechify/adaptors/cors';
import type { Callback } from 'lib/speechify/adaptors/lib/typeAliases';

import { ClassicURLListenableContent } from './impl/ClassicURLListenableContent';
import { PDFURLListenableContent } from './impl/PDFURLListenableContent';

// TODO(overhaul): This is duplicated from readers/newsdk/setup.ts, we should remove that file later.
const isPDF = async (url: string) => {
  const response = await fetch(wrapWithCorsProxy(url), { method: 'HEAD' });
  return response.headers.get('Content-Type') === ALLOWED_MIME_TYPES.PDF;
};

export const createURLListenableContent = async (
  importSource: NonInstantListeningImportSource,
  { folderId }: ImportOptions,
  importFlow: WebAppImportFlow,
  callback: Callback<SpeechifyURI>
) => {
  const { data } = importSource;
  const pdf = isUrlImport(data) ? await isPDF(data.url) : !CLASSIC_READER_MIME_TYPES.includes(importSource.mimeType);
  const url = isUrlImport(data) ? data.url : data;
  if (pdf) {
    return new PDFURLListenableContent({
      // this is important because PSPDFKit needs the CORS proxy and we won't be able to play transloaded/canvas PDF document if it's not behind this CORS proxy.
      url: wrapWithCorsProxy(url),
      name: importSource.name,
      folderId,
      mimeType: importSource.mimeType,
      onImportCompleteCallback: callback,
      importFlow
    });
  }
  return new ClassicURLListenableContent({
    url,
    name: importSource.name,
    folderId,
    mimeType: importSource.mimeType,
    onImportCompleteCallback: callback,
    importFlow
  });
};
